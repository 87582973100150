import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1
  },
  title: {
    display: 'block',
    color: 'white'
  }
}));

export default useStyles;