import React from 'react';
import { TextValidator } from 'react-material-ui-form-validator';
import { Typography, Box } from '@mui/material';
import PropTypes from 'prop-types';
import useStyles from './TypographyInputToggle.style';

function TypographyInputToggle (
  { name, editing, inputValue, typographyValue, handleChange, placeholder, label, ...rest }
) {

  const classes = useStyles();

  return (
    <Box className={classes.box}>
      <Typography variant="h6">{label}</Typography>
      {editing ? (
        <TextValidator
          className={`${classes.value} ${classes.input}`}
          name={name}
          variant="standard"
          value={inputValue}
          onChange={handleChange}
          placeholder={placeholder}
          {...rest}
        />
      ) : (<>
        <Typography className={classes.value} variant="subtitle1">{typographyValue}</Typography>
      </>
      )}
    </Box>
  );
}

TypographyInputToggle.defaultProps = {
  editing: true,
  typographyValue: '',
  inputValue: ''
};

TypographyInputToggle.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  editing: PropTypes.bool.isRequired,
  inputValue: PropTypes.string.isRequired,
  typographyValue: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default TypographyInputToggle;