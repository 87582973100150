import React, { useEffect } from 'react';
import useChange from '../../customHooks/useChange';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Typography,
  Button,
  Container,
  Link as MaterialLink
} from '@mui/material';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Link } from 'react-router-dom';
import Message from '../../components/Message/Message';
import { signUpAction } from '../../actions/userActions';
import useStyles from './Users.styles';
import { withSnack } from '../../providers/SnackProvider';


function SignUp ({ history, openSnack }) {
  const { user, loading, errorRegister } = useSelector(state => state.user);
  const dispatch = useDispatch();

  const [potentialUser, handleChange] = useChange({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    causewayUsername: '',
    causewayPassword: '',
    causewayDomain: ''
  });


  useEffect(function passwordMatchRule () {
    ValidatorForm.addValidationRule('isPasswordMatch', (val) => {
      const { password } = potentialUser;
      return password === val;
    });
    return () => ValidatorForm.removeValidationRule('isPasswordMatch');
  }, [potentialUser]);

  useEffect(function addRules () {
    ValidatorForm.addValidationRule('twoCharsAtLeast', (val) => {
      return val.length >= 2;
    });

    ValidatorForm.addValidationRule('sixCharsAtLeast', (val) => {
      return val.length >= 6;
    });
  }, []);

  useEffect(function redirectToHome () {
    if (user) {
      history.push('/settings');
    }
  }, [user, history]);

  function onSubmit (e) {
    e.preventDefault();
    dispatch(signUpAction(potentialUser, openSnack));
  }

  const classes = useStyles();

  return (
    <Container className={classes.root}>
      {errorRegister && <Message>{errorRegister}</Message>}
      <Box className={classes.box}>
        <Typography className={classes.typography} variant="h5">Create an account</Typography>
        <Typography className={classes.typography} variant="subtitle1">Welcome to HubSpot Sync</Typography>
      </Box>

      <ValidatorForm className={classes.form} onSubmit={onSubmit} instantValidate={false}>

        <TextValidator
          variant="standard"
          label="First Name"
          onChange={handleChange}
          name="firstName"
          placeholder="John"
          value={potentialUser.firstName}
          fullWidth
          validators={['required', 'twoCharsAtLeast']}
          errorMessages={['This field is required.', 'Must be two chars at least.']}
        />

        <TextValidator
          variant="standard"
          label="Last Name"
          onChange={handleChange}
          name="lastName"
          placeholder="Doe"
          value={potentialUser.lastName}
          fullWidth
          validators={['required', 'twoCharsAtLeast']}
          errorMessages={['This field is required.', 'Must be two chars at least.']}
        />

        <TextValidator
          variant="standard"
          label="E-mail"
          onChange={handleChange}
          name="email"
          placeholder="john.doe@gmail.com"
          value={potentialUser.email}
          autoComplete="username"
          fullWidth
          validators={['required', 'isEmail']}
          errorMessages={['This field is required.', 'Email is not valid.']}
        />

        <TextValidator
          variant="standard"
          label="Password"
          onChange={handleChange}
          name="password"
          value={potentialUser.password}
          type="password"
          autoComplete="new-password"
          fullWidth
          validators={['required', 'sixCharsAtLeast']}
          errorMessages={['This field is required.', 'Password must be of 6 chars at least.']}

        />

        <TextValidator
          variant="standard"
          label="Confirm Password"
          onChange={handleChange}
          name="confirmPassword"
          value={potentialUser.confirmPassword}
          type="password"
          autoComplete="new-password"
          fullWidth
          validators={['isPasswordMatch']}
          errorMessages={['Passwords mismatch.']}
        />

        <TextValidator
          variant="standard"
          label="Causeway Domain"
          onChange={handleChange}
          name="causewayDomain"
          value={potentialUser.causewayDomain}
          placeholder="members.mywebsite.com"
          fullWidth
          validators={['required']}
          errorMessages={['This field is required.']}
        />

        <TextValidator
          variant="standard"
          label="Causeway Username"
          onChange={handleChange}
          name="causewayUsername"
          value={potentialUser.causewayUsername}
          placeholder="my_causeway_username"
          fullWidth
          validators={['required', 'twoCharsAtLeast']}
          errorMessages={['This field is required.', 'Must be two chars at least.']}
        />

        <TextValidator
          variant="standard"
          label="Causeway password"
          onChange={handleChange}
          name="causewayPassword"
          value={potentialUser.causewayPassword}
          type="password"
          placeholder="my_causeway_password"
          fullWidth
          validators={['required']}
          errorMessages={['This field is required.']}
        />

        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          type="submit"
          disabled={loading}
        >
          Create My Account
        </Button>
      </ValidatorForm>
      <MaterialLink className={classes.link} component={Link} to="/login">
        Already have an account? Sign in
      </MaterialLink>
    </Container>
  );
}

export default withSnack(SignUp);