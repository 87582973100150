import React from 'react';
import { Box } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import SyncRequest from '../SyncRequest/SyncRequest';
import { styles, useStyles } from './SyncRequestModal.styles';

function SyncRequestModal ({ syncRequest, open, toggleOpen }) {
  const classes = useStyles();
  return (
    <div>
      <Modal
        open={open}
        onClose={toggleOpen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <Box className={classes.box} sx={styles}>
            <SyncRequest syncRequest={syncRequest}/>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default SyncRequestModal;