import React, { useState, memo } from 'react';
import useToggle from '../../customHooks/useToggle';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SyncRequestModal from '../SyncRequestModal/SyncRequestModal';
import SyncStatus from '../SyncStatus/SyncStatus';
import { formatDate } from '../../utils';

function CustomerTable ({ syncList }) {
  const [open, toggleOpen] = useToggle(false);
  const [syncRequest, setSyncRequest] = useState({});

  function openModal (index) {
    setSyncRequest(syncList[index]);
    toggleOpen();
  }

  function renderSyncRow (syncRequest, i) {
    const { status } = syncRequest;
    return (
      <TableRow key={syncRequest._id}>
        <TableCell>{formatDate(syncRequest.createdAt)}</TableCell>
        <TableCell>
          <SyncStatus status={status}/>
        </TableCell>
        <TableCell>
          <Button onClick={openModal.bind(this, i)}>
            <VisibilityIcon />
          </Button>
        </TableCell>
      </TableRow>
    );
  }


  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Requested At</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">View</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {syncList.map(renderSyncRow)}
          </TableBody>
        </Table>
      </TableContainer>

      <SyncRequestModal
        syncRequest={syncRequest}
        open={open}
        toggleOpen={toggleOpen}
      />
    </>
  );
}

export default memo(CustomerTable);