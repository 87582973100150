import axios from 'axios';
import { USER_TOKEN_RECEIVED } from './types';

async function refreshTokenRequest (refreshToken) {
  return await axios.post('/api/users/refresh-tokens/', {
    refreshToken
  });
}

export function storeAtLocalStorage (
  { accessToken, refreshToken, userId, expirationTimeInSeconds }
) {
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('refreshToken', refreshToken);
  localStorage.setItem(
    'accessTokenExpiresAt',
    Date.now() + expirationTimeInSeconds * 1000
  );
  if (userId) {
    localStorage.setItem('userId', userId);
  }
}

export async function getAccessToken (dispatch) {
  const accessTokenExpiresAt = +localStorage.getItem('accessTokenExpiresAt');
  const accessTokenHasExpired = Date.now() >= accessTokenExpiresAt;
  const accessTokenOld = localStorage.getItem('accessToken');
  if (!accessTokenHasExpired) {
    return accessTokenOld;
  } else {
    const refreshTokenOld = localStorage.getItem('refreshToken');
    try {
      const { data } = await refreshTokenRequest(refreshTokenOld);
      const { accessToken, refreshToken, expirationTimeInSeconds } = data;
      storeAtLocalStorage({ accessToken, refreshToken, expirationTimeInSeconds });
      dispatch({
        type: USER_TOKEN_RECEIVED,
        payload: { accessToken, refreshToken }
      });
      return accessToken;
    } catch (e) {
      return accessTokenOld;
    }
  }
}