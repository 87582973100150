import React, { useEffect } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import SyncIcon from '@mui/icons-material/Sync';
import PeopleIcon from '@mui/icons-material/People';
import { withRouter, Link, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Settings from '../HomeTabs/Settings';
import Sync from '../HomeTabs/Sync';
import Users from '../HomeTabs/Users';
import ContainerCustom from '../../components/Container/Container';
import HomeModal from '../../components/HomeModal/HomeModal';
import { Container, Box, Tabs, Tab } from '@mui/material';

function Home ({ history, location }) {
  const { user, firstTime, hubspotAccountId: hubspotAccount, accessToken } = useSelector(state => state.user);
  const hubspotAccountId = hubspotAccount || user?.accountId?.hubspotAccountId;
  const { pathname } = location;

  useEffect(function redirectOnlogOut () {
    if (!accessToken) {
      history.push('/login');
    } else if (pathname === '/') {
      history.push('/settings');
    }
  }, [accessToken, pathname]);

  return (
    <Container>
      {user && firstTime && !hubspotAccountId && <HomeModal/>}
      <ContainerCustom>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={pathname}
            textColor="primary"
            indicatorColor="primary"
            variant="fullWidth"
          >
            <Tab
              label={<><SettingsIcon/>&nbsp;Settings</>}
              value="/settings"
              component={Link}
              to="/settings"/>
            <Tab
              label={<><SyncIcon/>&nbsp;Sync</>}
              value="/sync"
              component={Link}
              to="/sync"/>
            <Tab
              label={<><PeopleIcon/>&nbsp;Users</>}
              value="/account-users"
              component={Link}
              to="/account-users"/>
          </Tabs>
        </Box>
        <Route path="/settings" component={Settings}/>
        <Route path="/sync" component={Sync}/>
        <Route path="/account-users" component={Users}/>
      </ContainerCustom>
    </Container>
  );
}

export default withRouter(Home);