import {
  SYNC_SUCCESS,
  SYNC_LOADING,
  SYNC_ERROR,
  USER_LOGOUT
} from '../actions/types';

export default function syncReducer (state = { sync: {} }, action) {
  switch (action.type) {
    case SYNC_LOADING:
      return { ...state, loading: true };
    case SYNC_SUCCESS:
      return { err: '', sync: action.payload, loading: false };
    case SYNC_ERROR:
      return { ...state, err: action.payload, loading: false };
    case USER_LOGOUT :
      return { sync: {} };
    default:
      return state;
  }
}