import React from 'react';
import { Box, Typography } from '@mui/material';
import useStyles from './Message.style';
import PropTypes from 'prop-types';

function Message ({ children, variant }) {
  const classes = useStyles();
  const variantClass = classes[variant];
  return (
    <Box className={`${classes.box} ${variantClass}`}>
      <Typography variant="subtitle1" component="p">
        {children}
      </Typography>
    </Box>
  );
}

Message.defaultProps = {
  variant: 'error'
};

Message.propTypes = {
  variant: PropTypes.oneOf(['error', 'success'])
};



export default Message;