import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  box: {
    width: 400,
    padding: 32,
    [theme.breakpoints.down('sm')]: {
      width: 300,
      padding: 22
    }
  },

  button: {
    margin: '1rem 0'
  },
  spacing: {
    marginBottom: '1rem'
  },
  input: {
    marginBottom: '1rem',
    '& label': {
      marginLeft: '0.7rem'
    }
  },
  inputWrapper: {
    display: 'flex'
  }
}));

const styles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  outline: 'none',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24
};

export { styles, useStyles };