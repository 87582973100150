import {
  SYNC_LIST_SUCCESS,
  SYNC_LIST_LOADING,
  SYNC_LIST_ERROR,
  USER_LOGOUT
} from '../actions/types';

export default function syncListReducer (state = { syncList: [] }, action) {
  switch (action.type) {
    case SYNC_LIST_LOADING:
      return { ...state, loading: true };
    case SYNC_LIST_SUCCESS:
      return {
        err: '',
        syncList: action.payload,
        pageNumber: action.pageNumber,
        pagesCount: action.pagesCount,
        loading: false };
    case SYNC_LIST_ERROR:
      return { ...state, err: action.payload, loading: false };
    case USER_LOGOUT :
      return { syncList: [] };
    default:
      return state;
  }
}