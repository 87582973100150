export const mainStyles = {
  width: '600px',
  maxWidth: '95%',
  margin: '0 auto',
  display: 'flex',
  alignItems: 'center'
};

export const pageStyles = {
  padding: '0.6rem',
  border: '2px solid rgba(0, 0, 0, 0.4)',
  borderRadius: '0.6rem',
  margin: '6rem auto',
  display: 'block'
};

export const lgStyles = {
  width: '95%'
};