import axios from 'axios';
import {
  MEMBERS_SUCCESS,
  MEMBERS_LOADING,
  MEMBERS_ERROR,
  MEMBERS_INVITED_USER
} from './types';
import { asyncHandler, snackBarCases, extractMessage, createConfig } from '../utils';
import { getAccessToken } from './tokenActions';

async function getMembersRequest ({ accessToken }) {
  return await axios.get('/api/customer/users/get-all-account-users/',
    createConfig({ accessToken }));
}

async function inviteUserRequest ({ accessToken, user }) {
  return await axios.post('/api/customer/users/invite-to-account/',
    { user }, createConfig({ accessToken }));
}


export function getMembersAction () {
  return async function (dispatch) {
    dispatch({ type: MEMBERS_LOADING });
    const accessToken = await getAccessToken(dispatch);
    const [res, err] = await asyncHandler(getMembersRequest, { accessToken });
    if (res) {
      for (const user of res.data.accountUsers) {
        user.isAccountOwner = user.roles?.includes('account-owner');
      }
      dispatch({ type: MEMBERS_SUCCESS, payload: res.data.accountUsers || [] });
    } else {
      const errorMessage = extractMessage({
        response: err.response,
        defaultMessage: 'Something went wrong while fetching users.',
        isError: true
      });
      dispatch({ type: MEMBERS_ERROR, payload: errorMessage });
    }
  };
}

export function inviteUserAction ({ user }, setUser, openSnack) {
  return async function (dispatch) {
    const accessToken = await getAccessToken(dispatch);
    const [res, err] = await asyncHandler(inviteUserRequest, { accessToken, user });
    if (res) {
      const createdUser = { email: user.email, isAccountOwner: user.role === 'account-owner' };
      dispatch({ type: MEMBERS_INVITED_USER, payload: createdUser });
      openSnack(res.data.message, snackBarCases.success);
      setUser({ email: '', role: '' });
    } else {
      const errorMessage = extractMessage({
        response: err.response,
        defaultMessage: 'Error occurred, try again.',
        isError: true
      });
      openSnack(errorMessage, snackBarCases.error);
    }
  };
}