import { makeStyles } from '@mui/styles';

export default makeStyles({
  textContainer: {
    margin: '1rem 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  text: {
    width: '50%',
    marginRight: '12px'
  }
});