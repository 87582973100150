import axios from 'axios';
import {
  SYNC_SUCCESS,
  SYNC_LOADING,
  SYNC_ERROR,
  SYNC_LIST_SUCCESS,
  SYNC_LIST_LOADING,
  SYNC_LIST_ERROR
} from './types';
import { asyncHandler, snackBarCases, extractMessage, createConfig } from '../utils';
import { getSettingsAction } from './settingsActions';
import { getAccessToken } from './tokenActions';

async function getSyncListRequest ({ accessToken, pageNumber, documentsPerPageCount }) {
  const page = pageNumber || 1;
  const perPage = documentsPerPageCount || 1;
  return await axios.get('/api/customer/sync-requests/list/',
    createConfig({ accessToken, page, perPage }));
}

async function updateSyncTimeRequest ({ accessToken, serverTiming }) {
  return await axios.put('/api/customer/accounts/update-daily-time-to-sync/', {
    account: { dailyTimeToSync: serverTiming }
  },
  createConfig({ accessToken })
  );
}

async function runSyncNowRequest ({ accessToken }) {
  return await axios.post('/api/customer/sync-requests/create/', {},
    createConfig({ accessToken }));
}

export function getLastSyncAction () {
  return async function (dispatch) {
    dispatch({ type: SYNC_LOADING });
    const accessToken = await getAccessToken(dispatch);
    const [res, err] = await asyncHandler(getSyncListRequest, { accessToken });
    if (res) {
      dispatch({ type: SYNC_SUCCESS, payload: res.data.syncRequests?.[0] || {} });
    } else {
      dispatch({
        type: SYNC_ERROR,
        payload: extractMessage({
          response: err.response,
          defaultMessage: 'Something went wrong while fetching last sync request.',
          isError: true
        })
      });
    }
  };
}

export function getSyncListAction (pageNumber, documentsPerPageCount) {
  return async function (dispatch) {
    const accessToken = await getAccessToken(dispatch);
    dispatch({ type: SYNC_LIST_LOADING });
    const [res, err] = await asyncHandler(getSyncListRequest,
      { accessToken, pageNumber, documentsPerPageCount }
    );
    if (res) {
      dispatch({
        type: SYNC_LIST_SUCCESS,
        payload: res.data.syncRequests || [],
        pagesCount: res.data.pagesCount,
        pageNumber
      });
    } else {
      dispatch({
        type: SYNC_LIST_ERROR,
        payload: extractMessage({ response: err.response, defaultMessage: 'Something went wrong while fetching sync requests.', isError: true }) });
    }
  };
}

export function updateSyncAction ({ serverTiming }, openSnack) {
  return async function (dispatch) {
    const accessToken = await getAccessToken(dispatch);
    const [res, err] = await asyncHandler(updateSyncTimeRequest, { accessToken, serverTiming });
    if (res) {
      dispatch(getSettingsAction());
      openSnack('Sync time is updated successfully.', snackBarCases.success);
    } else {
      const errorMessage = extractMessage({
        response: err.response,
        defaultMessage: 'Something went wrong while updating sync time, try again.',
        isError: true
      });
      openSnack(errorMessage, snackBarCases.error);
    }
  };
}

export function runSyncAction (openSnack) {
  return async function (dispatch) {
    const accessToken = await getAccessToken(dispatch);
    const [res, err] = await asyncHandler(runSyncNowRequest, { accessToken });
    if (res) {
      openSnack(res.data.message, snackBarCases.success);
      dispatch(getLastSyncAction(accessToken));
    } else {
      const errorMessage = extractMessage({
        response: err.response,
        defaultMessage: 'Something went wrong while running synchronization, try again.',
        isError: true
      });
      openSnack(errorMessage, snackBarCases.error);
    }
  };
}