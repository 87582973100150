import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  box: {
    width: 350,
    padding: 32,
    [theme.breakpoints.down('sm')]: {
      width: 300,
      padding: 22
    }
  },
  title: {
    marginBottom: '2rem',
    position: 'relative',
    '& small': {
      position: 'absolute',
      top: '90%',
      left: 0,
      whiteSpace: 'nowrap',
      fontSize: 12,
      color: '#888'

    }
  },
  inputWrapper: {
    display: 'flex'
  },
  input: {
    marginRight: '1rem'
  },

  select: {
    marginLeft: '.3rem'
  }
}));

const styles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  outline: 'none',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24
};

export { styles, useStyles };