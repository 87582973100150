import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/Logout';
import { useSelector, useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Container from '../Container/Container';
import useStyles from './Navbar.style';
import { logoutAction } from '../../actions/userActions';

function NavBarWrapper ({ isTeam, children }) {
  const classes = useStyles();
  if (isTeam) {
    return <>{children}</>;
  } else {
    return (
      <div className={classes.grow}>
        <AppBar position="fixed">
          {children}
        </AppBar>
      </div>);
  }
}


function Navbar ({ location, toggleOpen, showIfTeam }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const { accessToken, user } = useSelector(state => state.user);
  const dispatch = useDispatch();

  function handleMenu (event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose () {
    setAnchorEl(null);
  }

  function logout () {
    dispatch(logoutAction());
    handleClose();
  }

  const { pathname } = location;
  const isTeam = pathname.startsWith('/team');

  if (!accessToken) {
    return null;
  }

  if (isTeam && !showIfTeam) {
    return null;
  }

  return (
    <NavBarWrapper isTeam={showIfTeam}>
      <Toolbar>
        <Container isNav isLg={isTeam}>
          {isTeam && <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={toggleOpen}
          >
            <MenuIcon />
          </IconButton>}

          {!isTeam &&
            <Typography className={classes.title} variant="h6" noWrap>
              <Link className="link align-center" to="/settings"><HomeIcon/>&nbsp;Home</Link>
            </Typography>}
          <div className={classes.grow} />
          {user && <div>
            <IconButton
              size="medium"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircleIcon/>&nbsp;
              {user.firstName}
              <ArrowDropDownIcon/>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>
                <Typography className='primary' variant="h6" noWrap>
                  <Link className="link" to="/profile">My profile</Link>
                </Typography>
              </MenuItem>
              <MenuItem onClick={logout}>
                <Typography className='primary align-center' variant="h6" noWrap>
                  <LogoutIcon/> &nbsp;Logout
                </Typography>
              </MenuItem>
            </Menu>
          </div>}
        </Container>
      </Toolbar>
    </NavBarWrapper>
  );
}

export default withRouter(Navbar);