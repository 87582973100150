import {
  MEMBERS_SUCCESS,
  MEMBERS_LOADING,
  MEMBERS_ERROR,
  MEMBERS_INVITED_USER,
  USER_LOGOUT
} from '../actions/types';

export default function membersReducer (state = { loading: true, members: [] }, action) {
  switch (action.type) {
    case MEMBERS_LOADING:
      return { ...state, loading: true };
    case MEMBERS_SUCCESS:
      return { err: '', members: action.payload, loading: false };
    case MEMBERS_ERROR:
      return { ...state, err: action.payload, loading: false };
    case MEMBERS_INVITED_USER :
      return { ...state, members: [...state.members, action.payload] };
    case USER_LOGOUT :
      return { loading: true, members: [] };
    default:
      return state;
  }
}