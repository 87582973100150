import {
  USER_LOGGED_FAILED,
  USER_REGISTERED_FAILED,
  USER_TOKEN_RECEIVED,
  USER_LOGGED_SUCCESS,
  USER_UPDATE_PROFILE,
  USER_LOGGED_LOADING,
  USER_HUBSPOT_CONNECTIN,
  USER_HUBSPOT_RESET_CONNECTION,
  USER_LOGOUT
} from '../actions/types';

const err = { errorLogin: '', errorRegister: '' };

export default function userReducer (state = {}, action) {
  switch (action.type) {
    case USER_LOGGED_LOADING:
      return { ...state, ...err, loading: true };
    case USER_TOKEN_RECEIVED:
      return { ...state, ...err, ...action.payload, loading: false };
    case USER_LOGGED_SUCCESS:
      return { ...state, ...err, user: action.payload, firstTime: action.firstTime, loading: false };
    case USER_UPDATE_PROFILE:
      return { ...state, ...err, user: { ...state.user, ...action.payload } };
    case USER_LOGGED_FAILED:
      return { errorLogin: action.payload, loading: false };
    case USER_REGISTERED_FAILED:
      return { errorRegister: action.payload, loading: false };
    case USER_HUBSPOT_CONNECTIN:
      return { ...state, hubspotAccountId: action.payload };
    case USER_HUBSPOT_RESET_CONNECTION:
      return { ...state,
        firstTime: false,
        hubspotAccountId: undefined,
        user: {
          ...state.user, accountId: {
            ...state.user.accountId, hubspotAccountId: undefined
          } } };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
}