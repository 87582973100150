import {
  SETTINGS_SUCCESS,
  SETTINGS_LOADING,
  SETTINGS_TOGGLE_SYNC,
  SETTINGS_UPDATED,
  SETTINGS_ERROR,
  USER_LOGOUT
} from '../actions/types';

export default function settingsReducer (state = { settings: {} }, action) {
  switch (action.type) {
    case SETTINGS_LOADING:
      return { ...state, loading: true };
    case SETTINGS_SUCCESS:
      return { err: '', settings: action.payload, loading: false };
    case SETTINGS_UPDATED:
      return { ...state, settings: { ...state.settings, ...action.payload } };
    case SETTINGS_TOGGLE_SYNC:
      return { ...state, settings: {
        ...state.settings,
        isScheduledSyncEnabled: !state.settings.isScheduledSyncEnabled
      } };
    case SETTINGS_ERROR:
      return { ...state, err: action.payload, loading: false };
    case USER_LOGOUT :
      return { settings: {} };
    default:
      return state;
  }
}