import { makeStyles } from '@mui/styles';

export default makeStyles({
  root: {
    minHeight: '80vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '25rem',
    maxWidth: '92%',
    margin: '2rem auto'
  },
  box: {
    padding: '1rem',
    width: '100%',
    backgroundColor: '#3f51b5',
    textAlign: 'center'
  },
  typography: {
    color: 'white'
  },
  form: {
    width: '100%',
    padding: '1rem',
    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)'
  },
  button: {
    width: '100%',
    margin: '2rem auto 1rem',
    borderRadius: '0'
  },
  link: {
    marginTop: '1rem'
  }
});