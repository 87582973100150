import React, { useEffect } from 'react';
import {
  Typography,
  Box,
  Pagination
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import SyncRequestTable from '../../components/SyncRequestTable/CustomerTable';
import Container from '../../components/Container/Container';
import Message from '../../components/Message/Message';
import Loader from '../../components/Loader/Loader';
import { getSyncListAction } from '../../actions/syncActions';
import useStyles from './SyncList.styles';

function SyncList ({ match, history }) {
  const { accessToken } = useSelector(state => state.user);
  const { syncList, loading, pageNumber, pagesCount, err } = useSelector(state => state.syncList);
  const { page } = match.params;
  const dispatch = useDispatch();

  useEffect(function getSyncList () {
    if (!accessToken) {
      history.push('/login');
    } else if (+page !== pageNumber) {
      dispatch(getSyncListAction(page, 10));
    }
  }, [accessToken, page]);


  function handleChange (e, page) {
    history.push(`/sync-requests/${page}`);
  }

  const classes = useStyles();
  return (
    <Container>
      <Box className={classes.box}>
        {err && <Message>{err}</Message>}
        <Typography className={classes.spacing} variant="h4">Sync Requests</Typography>

        <SyncRequestTable syncList={syncList}/>

        {loading && <Loader/>}
        <Pagination
          className={classes.spacing}
          color="primary"
          defaultPage={+page}
          count={pagesCount || 1}
          disabled={loading}
          onChange={handleChange}
        />
      </Box>
    </Container>
  );
}

export default SyncList;