import React from 'react';
import ReactDOM from 'react-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import SnackProvider from './providers/SnackProvider';
import store from './store';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3f51b5'
    }
  },
  components: {
    MuiTableCell: {
      defaultProps: {
        align: 'center'
      }
    }
  }
});


ReactDOM.render(
  <ThemeProvider theme={theme}>
    <StyledEngineProvider injectFirst>
      <Provider store={store}>
        <SnackProvider>
          <App />
        </SnackProvider>
      </Provider>
    </StyledEngineProvider>
  </ThemeProvider>,
  document.getElementById('root')
);