import React, { memo } from 'react';
import { Box, Typography } from '@mui/material';
import SyncStatus from '../SyncStatus/SyncStatus';
import useStyles from './SyncRequest.styles';
import { formatDate } from '../../utils';

function SyncRequest ({ syncRequest }) {
  const {
    createdAt,
    totalActiveCausewayContactsCount,
    newContactsAddedToHubspotCount,
    oldContactsUpdatedOnHubspotCount,
    contactsWhoBecameActiveCount,
    contactsWhoBecameInactiveCount,
    status,
    syncResult
  } = syncRequest;

  const classes = useStyles();
  return (
    <>
      <Box className={classes.textContainer}>
        <Typography className={classes.text} variant="subtitle1">
          Date and time:
        </Typography>
        <Typography variant="subtitle2">
          {formatDate(createdAt) || 'N/A'}
        </Typography>
      </Box>
      <Box className={classes.textContainer}>
        <Typography className={classes.text} variant="subtitle1">
          Status:
        </Typography>
        <Typography variant="subtitle2">
          <SyncStatus status={status}/>
        </Typography>
      </Box>

      {
        status === 'failed' &&
          <Box className={classes.textContainer}>
            <Typography className={classes.text} variant="subtitle1">
          Failure reason:
            </Typography>
            <Typography variant="subtitle2">
              {syncResult?.message || 'Internal server error. Please contact: hubspot-sync@rarekarma.com.'}
            </Typography>
          </Box>
      }

      {
        status === 'succeeded' &&
          <>
            <Box className={classes.textContainer}>
              <Typography className={classes.text} variant="subtitle1">
          Total active Causeway users:
              </Typography>
              <Typography variant="subtitle2">
                {totalActiveCausewayContactsCount != null ? totalActiveCausewayContactsCount : 'N/A'}
              </Typography>
            </Box>
            <Box className={classes.textContainer}>
              <Typography className={classes.text} variant="subtitle1">
          New contacts:
              </Typography>
              <Typography variant="subtitle2">
                {newContactsAddedToHubspotCount != null ? newContactsAddedToHubspotCount : 'N/A' }
              </Typography>
            </Box>
            <Box className={classes.textContainer}>
              <Typography className={classes.text} variant="subtitle1">
          Total HubSpot contacts from Causeway:
              </Typography>
              <Typography variant="subtitle2">
                {oldContactsUpdatedOnHubspotCount != null ? oldContactsUpdatedOnHubspotCount : 'N/A' }
              </Typography>
            </Box>
            <Box className={classes.textContainer}>
              <Typography className={classes.text} variant="subtitle1">
          Became active:
              </Typography>
              <Typography variant="subtitle2">
                {contactsWhoBecameActiveCount != null ? contactsWhoBecameActiveCount : 'N/A' }
              </Typography>
            </Box>
            <Box className={classes.textContainer}>
              <Typography className={classes.text} variant="subtitle1">
          Became inactive:
              </Typography>
              <Typography variant="subtitle2">
                {contactsWhoBecameInactiveCount != null ? contactsWhoBecameInactiveCount : 'N/A' }
              </Typography>
            </Box>
          </>
      }

    </>
  );
}

SyncRequest.defaultProps = {
  syncRequest: {}
};

export default memo(SyncRequest);