import { combineReducers } from 'redux';
import userReducer from './userReducer';
import settingsReducer from './settingsReducer';
import syncReducer from './syncReducer';
import syncListReducer from './syncListReducer';
import membersReducer from './membersReducer';
import { loadingBarReducer } from 'react-redux-loading-bar';

export default combineReducers({
  user: userReducer,
  settings: settingsReducer,
  sync: syncReducer,
  syncList: syncListReducer,
  members: membersReducer,
  loadingBar: loadingBarReducer
});