import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Home from './Home';
import { hubspotCallbackAction } from '../../actions/userActions';
import { withSnack } from '../../providers/SnackProvider';

function OAuth ({ location, history, openSnack }) {
  const dispatch = useDispatch();

  const urlParams = new URLSearchParams(location.search);
  const code = urlParams.get('code') || '';

  useEffect(function initiateConnection () {
    dispatch(hubspotCallbackAction({ code }, openSnack));
    history.push('/');
  }, []);

  return (
    <Home/>
  );
}

export default withSnack(OAuth);