import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { ValidatorForm } from 'react-material-ui-form-validator';
import useToggle from '../../customHooks/useToggle';
import useChange from '../../customHooks/useChange';
import {
  Typography,
  Box,
  Button,
  Link as MaterialLink
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { withSnack } from '../../providers/SnackProvider';
import Container from '../../components/Container/Container';
import { updateProfileAction } from '../../actions/userActions';
import TypographyInputToggle from '../../components/TypographyInputToggle/TypographyInputToggle';
import useStyles from './Profile.styles';

function Profile ({ history, openSnack }) {
  const [loading, setLoading] = useState(false);
  const { user, accessToken } = useSelector(state => state.user);
  const [userData, handleChange, setData] = useChange({
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email
  });
  const [editing, toggleEditing] = useToggle(false);
  const dispatch = useDispatch();

  useEffect(function goToLoginIfNotSigned () {
    if (!accessToken) {
      history.push('/login');
    }
  }, [accessToken, history]);

  useEffect(function changeUserData () {
    setData({
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email
    });
  }, [user]);

  async function handleSubmit (e) {
    e.preventDefault();
    setLoading(true);
    await dispatch(updateProfileAction({ user: userData }, openSnack));
    toggleEditing(false);
    setLoading(false);
  }

  const classes = useStyles();

  return (
    <Container>
      <Box className={classes.box}>
        <Box className={classes.wrapper}>
          <Typography variant="h4">My profile</Typography>
          <Button color="primary" onClick={toggleEditing}>
            {editing ? 'Cancel' : <><EditIcon/>&nbsp;Edit</>}
          </Button>
        </Box>
        <ValidatorForm onSubmit={handleSubmit} instantValidate={false}>
          <TypographyInputToggle
            name="firstName"
            label="First Name"
            placeholder="John"
            editing={editing}
            inputValue={userData.firstName}
            typographyValue={user?.firstName}
            handleChange={handleChange}
            validators={['required']}
            errorMessages={['This field is required.']}

          />
          <TypographyInputToggle
            name="lastName"
            label="Last Name"
            placeholder="Doe"
            editing={editing}
            inputValue={userData.lastName}
            typographyValue={user?.lastName}
            handleChange={handleChange}
            validators={['required']}
            errorMessages={['This field is required.']}

          />
          <TypographyInputToggle
            name="email"
            label="E-mail"
            placeholder="johndoe@gmail.com"
            editing={editing}
            inputValue={userData.email}
            typographyValue={user?.email}
            handleChange={handleChange}
            validators={['required', 'isEmail']}
            errorMessages={['This field is required.', 'Email is not valid.']}
          />
          <Box className={`${classes.wrapper} ${classes.reverse}`}>
            <MaterialLink className={classes.link} component={Link} to="/change-password">
              Change Password
            </MaterialLink>

            {editing && <div>
              <Button type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
              >
                Save
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={toggleEditing}
              >
                Cancel
              </Button>
            </div>
            }
          </Box>
        </ValidatorForm>
      </Box>
    </Container>
  );
}

export default withSnack(Profile);