import { makeStyles } from '@mui/styles';

export default makeStyles({
  status: {
    transform: 'translateX(-6px)',
    '& p': {
      display: 'inline-block',
      transform: 'translateY(-5px)'
    },
    '& p::first-letter': {
      textTransform: 'uppercase'
    }
  }
});