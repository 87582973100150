import React, { useState } from 'react';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useDispatch } from 'react-redux';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Box,
  Button,
  Typography,
  FormControl,
  Select,
  InputLabel,
  MenuItem
} from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { withSnack } from '../../providers/SnackProvider';
import { inviteUserAction } from '../../actions/membersActions';
import { useStyles, styles } from './UsersInviteModal.style';

function UsersInviteModal ({ openSnack, open, toggleOpen }) {
  // To disable button during request
  const [inviting, setInviting] = useState(false);
  const [user, setUser] = useState({ email: '', role: '' });
  const dispatch = useDispatch();


  async function inviteUserSubmit (e) {
    e.preventDefault();
    setInviting(true);
    await dispatch(inviteUserAction({ user }, setUser, openSnack));
    toggleOpen(false);
    setInviting(false);
  }

  function handleChange ({ target }) {
    setUser({ ...user, [target.name]: target.value });
  }


  const classes = useStyles();

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={toggleOpen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <Box className={classes.box} sx={styles}>
            <Typography className={classes.spacing} variant="h6">Invite a user</Typography>
            <ValidatorForm onSubmit={inviteUserSubmit} instantValidate={false}>

              <TextValidator
                className={classes.input}
                variant="standard"
                label="E-mail"
                onChange={handleChange}
                name="email"
                value={user.email}
                fullWidth
                required
                validators={['isEmail']}
                errorMessages={['Email is not valid.']}
              />

              <FormControl fullWidth>
                <InputLabel>Role</InputLabel>
                <Select
                  variant="outlined"
                  name="role"
                  value={user.role}
                  label="Role"
                  onChange={handleChange}
                  required
                >
                  <MenuItem value='account-owner'>Owner</MenuItem>
                  <MenuItem value='account-agent'>Agent</MenuItem>
                </Select>
              </FormControl>

              <div className="buttons-container">
                <Button className={classes.button}
                  variant="contained"
                  color="primary"
                  disabled={inviting}
                  type="submit">
                  <PersonAddIcon/>&nbsp;Invite
                </Button>
                <Button className={classes.button}
                  variant="outlined"
                  color="primary"
                  onClick={toggleOpen}
                >
                  Cancel
                </Button>
              </div>
            </ValidatorForm>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default withSnack(UsersInviteModal);