export const USER_LOGGED_LOADING = 'USER_LOGGED_LOADING';
export const USER_TOKEN_RECEIVED = 'USER_TOKEN_RECEIVED';
export const USER_LOGGED_SUCCESS = 'USER_LOGGED_SUCCESS';
export const USER_UPDATE_PROFILE = 'USER_UPDATE_PROFILE';
export const USER_LOGGED_FAILED = 'USER_LOGGED_FAILED';
export const USER_REGISTERED_FAILED = 'USER_REGISTERED_FAILED';
export const USER_HUBSPOT_CONNECTIN = 'USER_HUBSPOT_CONNECTIN';
export const USER_HUBSPOT_RESET_CONNECTION = 'USER_HUBSPOT_RESET_CONNECTION';
export const USER_LOGOUT = 'USER_LOGOUT';

export const SETTINGS_LOADING = 'SETTINGS_LOADING';
export const SETTINGS_SUCCESS = 'SETTINGS_SUCCESS';
export const SETTINGS_TOGGLE_SYNC = 'SETTINGS_TOGGLE_SYNC';
export const SETTINGS_UPDATED = 'SETTINGS_UPDATED';
export const SETTINGS_ERROR = 'SETTINGS_LOADING';

export const SYNC_LOADING = 'SYNC_LOADING';
export const SYNC_SUCCESS = 'SYNC_SUCCESS';
export const SYNC_ERROR = 'SYNC_LOADING';

export const SYNC_LIST_LOADING = 'SYNC_LIST_LOADING';
export const SYNC_LIST_SUCCESS = 'SYNC_LIST_SUCCESS';
export const SYNC_LIST_ERROR = 'SYNC_LIST_LOADING';

export const MEMBERS_LOADING = 'MEMBERS_LOADING';
export const MEMBERS_SUCCESS = 'MEMBERS_SUCCESS';
export const MEMBERS_ERROR = 'MEMBERS_ERROR';
export const MEMBERS_INVITED_USER = 'MEMBERS_INVITED_USER';