import React, { useState, useEffect } from 'react';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import DeleteIcon from '@mui/icons-material/DeleteForeverOutlined';
import { ValidatorForm } from 'react-material-ui-form-validator';
import EditIcon from '@mui/icons-material/Edit';
import useToggle from '../../customHooks/useToggle';
import useChange from '../../customHooks/useChange';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Typography, Button, Divider } from '@mui/material';
import Loader from '../../components/Loader/Loader';
import TypographyInputToggle from '../../components/TypographyInputToggle/TypographyInputToggle';
import { withSnack } from '../../providers/SnackProvider';
import useStyles from './HomeTabs.style';
import { asyncHandler, snackBarCases, extractMessage } from '../../utils';
import { updateCausewayAction } from '../../actions/settingsActions';
import { resetConnectionAction } from '../../actions/userActions';
import { BASE_URL } from '../../CONSTANTS';

function Settings ({ openSnack }) {
  // This state is to disable buttons during requests to prevent multiple calls
  const [verifying, setVerifying] = useState(false);
  const [resetting, setResetting] = useState(false);
  const [updating, setUpdating] = useState(false);

  const [editing, toggleEditing] = useToggle(false);
  const { user, accessToken, hubspotAccountId } = useSelector(state => state.user);
  const { settings } = useSelector(state => state.settings);
  const { causewayUsername, causewayDomain } = settings;
  const { portalId, hubDomain } = settings.hubspotAccountId || {};

  const [account, handleChange, setAccount] = useChange({
    causewayUsername,
    causewayDomain,
    causewayPassword: ''
  });

  const dispatch = useDispatch();

  useEffect(function getAccountFromStore () {
    setAccount({
      causewayUsername,
      causewayDomain,
      causewayPassword: ''
    });
  }, [settings]);

  async function validateCredentialsRequest () {
    return await axios.get('/api/customer/accounts/validate-causeway-credentials/', {
      headers: { Authorization: `Bearer ${accessToken}` }
    });
  }

  async function validateClick () {
    setVerifying(true);
    const [res, err] = await asyncHandler(validateCredentialsRequest);
    if (res?.data.credentialsAreValid) {
      openSnack('Correct Causeway credentials.', snackBarCases.success);
    } else {
      const errorMessage = extractMessage({
        response: err.response,
        defaultMessage: 'Something went wrong while verifying Causeway credentials.',
        isError: true
      });

      openSnack(errorMessage, snackBarCases.error);
    }
    setVerifying(false);
  }

  async function resetConnection () {
    setResetting(true);
    await dispatch(resetConnectionAction(openSnack));
    setResetting(false);
  }

  async function handleSubmit (e) {
    e.preventDefault();
    setUpdating(true);
    await dispatch(updateCausewayAction({ account }, openSnack));
    toggleEditing(false);
    setUpdating(false);
  }


  const classes = useStyles();
  return (
    <Box className={classes.box}>
      <Box className={classes.wrapper}>
        <Typography variant="h4">Causeway Settings</Typography>
        {user?.isAccountOwner &&
        <Button className="align-center" onClick={toggleEditing} color="primary">
          {editing ? 'Cancel' : <><EditIcon/>&nbsp; Edit</>}
        </Button>}
      </Box>
      <ValidatorForm onSubmit={handleSubmit} instantValidate={false}>
        <TypographyInputToggle
          name="causewayDomain"
          label="Domain"
          placeholder="members.mipi.org"
          editing={editing}
          inputValue={account.causewayDomain}
          typographyValue={causewayDomain}
          handleChange={handleChange}
          validators={['required']}
          errorMessages={['This field is required.']}
        />

        <TypographyInputToggle
          name="causewayUsername"
          label="Username"
          placeholder="john"
          editing={editing}
          inputValue={account.causewayUsername}
          typographyValue={causewayUsername}
          handleChange={handleChange}
          validators={['required']}
          errorMessages={['This field is required.']}
        />

        {editing &&
        <TypographyInputToggle
          name="causewayPassword"
          label="Password"
          placeholder="Enter your causeway password"
          editing={editing}
          inputValue={account.causewayPassword}
          handleChange={handleChange}
          type="password"
          validators={['required']}
          errorMessages={['This field is required.']}
        />}

        {editing &&
        <>
          <Button type="submit"
            variant="contained"
            color="primary"
            disabled={updating}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={toggleEditing}
          >
            Cancel
          </Button>
        </>
        }

      </ValidatorForm>

      {!editing && <Button className={classes.button}
        variant="contained"
        color="primary"
        disabled={verifying}
        onClick={validateClick}
      >
        {verifying && <Loader size={15} margin={0}/>}
        <PersonSearchIcon/>&nbsp; Verify causeway credentials
      </Button>}


      <Divider className={classes.divider}/>

      <Typography className={classes.title} variant="h4">Hubspot Settings</Typography>
      {
        (hubspotAccountId || user?.accountId.hubspotAccountId) ? (
          <>
            <Typography variant="subtitle1">
              Connected with: {`${hubDomain} (${portalId})`}
            </Typography>
            {user?.isAccountOwner && (
              <Button className={classes.button}
                variant="contained"
                color="error"
                disabled={resetting}
                onClick={resetConnection}>
                <DeleteIcon/>&nbsp; Reset hubspot connection
              </Button>)}
          </>
        ) : (
          <a className="link" href={`${BASE_URL}/api/customer/hubspot-accounts/connect/`}>
            <Button className={classes.button} variant="contained" color="primary">Connect to hubspot</Button>
          </a>
        )
      }
    </Box>
  );
}

export default withSnack(Settings);