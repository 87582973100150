import { makeStyles } from '@mui/styles';

export default makeStyles({
  box: {
    margin: '2rem 0'
  },
  value: {
    color: '#333',
    margin: '1rem 0 0 0.5rem'
  },
  input: {
    width: 300,
    maxWidth: '90%'
  }
});