import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducer from './reducers';

const middlewares = [thunk];

const accessToken = localStorage.getItem('accessToken') || undefined;

const initialState = {
  user: {
    accessToken,
    firstTime: false
  }
};

const store = createStore(
  reducer,
  initialState,
  applyMiddleware(...middlewares)
);

export default store;