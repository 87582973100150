import React, { useState, useEffect, useCallback, createContext, forwardRef } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const SnackContext = createContext();

const Alert = forwardRef(function (props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function SnackProvider ({ children }) {
  const [query, setQuery] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');

  const openSnack = useCallback(function openSnack (message, severity) {
    if (!open) {
      setOpen(true);
      setMessage(message);
      setSeverity(severity);
    } else {
      setQuery([...query, { message, severity }]);
    }
  }, [open, query]);

  useEffect(function callSnackBarFromQuery () {
    if (!open && query.length) {
      const snackObj = query.pop();
      setTimeout(function callSnackBar () {
        openSnack(snackObj.message, snackObj.severity);
      }, 1000);
    }
  }, [open, query, openSnack]);


  function closeSnack (event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={open}
        autoHideDuration={3000}
        onClose={closeSnack}
      >
        <Alert onClose={closeSnack} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
      <SnackContext.Provider
        value={openSnack}
      >
        {children}
      </SnackContext.Provider>
    </>
  );

}

export function withSnack (Component) {
  return class SnackConsumer extends React.Component {
    render () {
      return (
        <SnackContext.Consumer>
          {value => <Component openSnack={value} {...this.props} />}
        </SnackContext.Consumer>
      );
    }
  };
}

export default SnackProvider;