import React, { useEffect } from 'react';
import useChange from '../../customHooks/useChange';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Box,
  Typography,
  Link as MaterialLink
} from '@mui/material';
import Message from '../../components/Message/Message';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Link } from 'react-router-dom';
import { logInAction } from '../../actions/userActions';
import useStyles from './Users.styles';
import { withSnack } from '../../providers/SnackProvider';

function Login ({ history, location, isTeam, openSnack }) {
  const [potentialUser, handleChange] = useChange({
    email: '',
    password: ''
  });
  const dispatch = useDispatch();
  const { loading, accessToken } = useSelector(state => state.user);

  useEffect(function addRules () {
    ValidatorForm.addValidationRule('sixCharsAtLeast', (val) => {
      return val.length >= 6;
    });
  }, []);

  useEffect(function redirectToHome () {
    if (accessToken && !isTeam) {
      history.push('/settings');
    }
  }, [accessToken, history]);

  function onSubmit (e) {
    e.preventDefault();
    const { email, password } = potentialUser;
    dispatch(logInAction({ email, password }, openSnack));
  }

  const msg = location?.state?.message || '';

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {msg && <Message variant="success">{msg}</Message>}
      <Box className={classes.box}>
        <Typography className={classes.typography} variant="h5">
          {isTeam && 'Team '}Login
        </Typography>
        <Typography className={classes.typography} variant="subtitle1">Welcome to HubSpot Sync</Typography>
      </Box>
      <ValidatorForm className={classes.form} onSubmit={onSubmit} instantValidate={false}>
        <TextValidator
          variant="standard"
          label="E-mail"
          onChange={handleChange}
          name="email"
          value={potentialUser.email}
          autoComplete="username"
          fullWidth
          validators={['required', 'isEmail']}
          errorMessages={['This field is required.', 'Email is not valid.']}
        />

        <TextValidator
          variant="standard"
          label="Password"
          onChange={handleChange}
          name="password"
          value={potentialUser.password}
          type="password"
          autoComplete="current-password"
          fullWidth
          validators={['required', 'sixCharsAtLeast']}
          errorMessages={['This field is required', 'Password must be of 6 characters at least.']}

        />
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          type="submit"
          disabled={loading}
        >
          Login
        </Button>
      </ValidatorForm>
      <MaterialLink className={classes.link} component={Link} to="/signup">
        Create a new account
      </MaterialLink>
      <MaterialLink className={classes.link} component={Link} to="/forgot-password">
        Forgot your password?
      </MaterialLink>
    </div>
  );
}

export default withSnack(Login);