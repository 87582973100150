import { useState } from 'react';

function useChange (obj) {
  const [data, setData] = useState(obj);

  function handleChange ({ target }) {
    setData({ ...data, [target.name]: target.value });
  }

  return [data, handleChange, setData];
}

export default useChange;