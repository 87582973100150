import { useState } from 'react';

export default function useToggle (initial) {
  const [value, setValue] = useState(initial);
  function toggle (state) {
    if (typeof state === 'boolean') {
      setValue(state);
    } else {
      setValue(!value);
    }
  }
  return [value, toggle];
}