import React, { lazy, Suspense, useEffect } from 'react';
import useToggle from './customHooks/useToggle';
import { useSelector, useDispatch } from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import LoadingBar from 'react-redux-loading-bar';

import { getProfileAction } from './actions/userActions';
import { withSnack } from './providers/SnackProvider';

import './App.css';
import Home from './routes/Home/Home';
import SyncList from './routes/SyncList/SyncList';
import Profile from './routes/Profile/Profile';
import OAuth from './routes/Home/OAuth';
import SignUp from './routes/Users/SignUp';
import Login from './routes/Users/Login';

import Navbar from './components/Navbar/Navbar';
import Loader from './components/Loader/Loader';

const ForgotPassword = lazy(() => import('./routes/Users/ForgotPassword'));
const ResetPassword = lazy(() => import('./routes/Users/ResetPassword'));
const ChangePassword = lazy(() => import('./routes/Profile/ChangePassword'));
const Team = lazy(() => import('./routes/Team/Team'));

function App ({ openSnack }) {
  const [open, toggleOpen] = useToggle(true);
  const { accessToken } = useSelector(state => state.user);
  const dispatch = useDispatch();

  useEffect(function getUserByToken () {
    if (accessToken) {
      dispatch(getProfileAction(openSnack, false));
    }
  }, [dispatch]);

  return (
    <Router>
      <Navbar toggleOpen={toggleOpen}/>
      <LoadingBar />
      <main>
        <Switch>
          <Route path="/hubspot-callback" component={OAuth}/>
          <Route path="/login" component={Login}/>
          <Route path="/sync-requests/:page" component={SyncList}/>

          <Route path="/forgot-password">
            <Suspense fallback={<Loader/>}>
              <ForgotPassword/>
            </Suspense>
          </Route>

          <Route path="/team">
            <Suspense fallback={<Loader/>}>
              <Team open={open} toggleOpen={toggleOpen}/>
            </Suspense>
          </Route>

          <Route path="/reset-my-password-with-token">
            <Suspense fallback={<Loader/>}>
              <ResetPassword/>
            </Suspense>
          </Route>

          <Route path="/change-password">
            <Suspense fallback={<Loader/>}>
              <ChangePassword/>
            </Suspense>
          </Route>

          <Route path="/signup" component={SignUp}/>
          <Route path="/profile" component={Profile}/>
          <Route path="/">{accessToken ? <Home /> : <Redirect to="login"/>}</Route>
        </Switch>
      </main>
    </Router>
  );
}

export default withSnack(App);