import axios from 'axios';
import {
  SETTINGS_SUCCESS,
  SETTINGS_LOADING,
  SETTINGS_TOGGLE_SYNC,
  SETTINGS_UPDATED,
  SETTINGS_ERROR
} from './types';
import { asyncHandler, snackBarCases, extractMessage, createConfig } from '../utils';
import { getAccessToken } from './tokenActions';

async function getSettingsRequest ({ accessToken }) {
  return await axios.get('/api/customer/accounts/view/', createConfig({ accessToken }));
}

async function updateCausewayRequest ({ accessToken, account }) {
  return await axios.put('/api/customer/accounts/update-causeway-credentials/',
    { account },
    createConfig({ accessToken })
  );
}

async function toggleSyncRequest ({ accessToken, isScheduledSyncEnabled }) {
  return await axios.put('/api/customer/accounts/toggle-scheduled-sync/', {
    account: { isScheduledSyncEnabled: !isScheduledSyncEnabled }
  },
  createConfig({ accessToken })
  );
}

export function getSettingsAction () {
  return async function (dispatch) {
    const accessToken = await getAccessToken(dispatch);
    dispatch({ type: SETTINGS_LOADING });
    const [res, err] = await asyncHandler(getSettingsRequest, { accessToken });
    if (res) {
      dispatch({ type: SETTINGS_SUCCESS, payload: res.data.account });
    } else {
      dispatch({
        type: SETTINGS_ERROR,
        payload: extractMessage(err, 'Something went wrong while fetching account data.') });
    }
  };
}

export function updateCausewayAction ({ account }, openSnack) {
  return async function (dispatch) {
    const accessToken = await getAccessToken(dispatch);
    const [res, err] = await asyncHandler(updateCausewayRequest, { accessToken, account });
    if (res) {
      delete account.causewayPassword;
      openSnack(res.data, snackBarCases.success);
      dispatch({ type: SETTINGS_UPDATED, payload: account });
    } else {
      const errorMessage = extractMessage({
        response: err.response,
        defaultMessage: 'Something went wrong while updating Causeway info, please try again.',
        isError: true
      });

      openSnack(errorMessage, snackBarCases.error);
    }
  };
}

export function toggleSyncAction ({ isScheduledSyncEnabled }, openSnack) {
  return async function (dispatch) {
    const accessToken = await getAccessToken(dispatch);
    const [res, err] = await asyncHandler(toggleSyncRequest, { accessToken, isScheduledSyncEnabled });
    if (res) {
      openSnack(res.data.message, snackBarCases.success);
      dispatch({ type: SETTINGS_TOGGLE_SYNC });
    } else {
      const errorMessage = extractMessage({
        response: err.response,
        defaultMessage: 'Something went wrong while toggling sync state, try again.',
        isError: true
      });
      openSnack(errorMessage, snackBarCases.error);
    }
  };
}