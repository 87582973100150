import { makeStyles } from '@mui/styles';

export default makeStyles({
  box: {
    width: '100%',
    padding: '0.5rem 1rem',
    marginBottom: '1.5rem'
  },
  error: {
    backgroundColor: '#f8d7da',
    border: '1px solid #f5c2c7',
    '& p': {
      color: '#842029'
    }
  },
  success: {
    backgroundColor: '#d1e7dd',
    border: '1px solid #badbcc',
    '& p': {
      color: '#0f5132'
    }
  }
});