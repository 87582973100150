import React from 'react';
import Box from '@mui/material/Box';
import { mainStyles, pageStyles, lgStyles } from './Container.styles';
import PropTypes from 'prop-types';

function Container ({ children, isNav, isLg }) {
  const styles = {
    ...mainStyles,
    ...(!isNav && pageStyles),
    ...(isLg && lgStyles)
  };
  return (
    <Box sx={styles}>
      {children}
    </Box>
  );
}

Container.propTypes = {
  isNav: PropTypes.bool,
  isLg: PropTypes.bool
};

export default Container;