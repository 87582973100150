import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  box: {
    margin: '2rem',
    [theme.breakpoints.down('sm')]: {
      margin: '0.8rem'
    }
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: {
    marginBottom: '1rem'
  },
  textContainer: {
    margin: '1rem 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  text: {
    width: '50%',
    marginRight: '12px'
  },
  adjustSwitch: {
    transform: 'translateX(-14px)'
  },
  divider: {
    margin: '1rem 0'
  },
  button: {
    margin: '1rem 0',
    width: '23rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  time: {
    position: 'relative',
    '& small': {
      position: 'absolute',
      top: '85%',
      left: 0,
      whiteSpace: 'nowrap',
      color: '#888'
    }
  }
}));