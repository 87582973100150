import moment from 'moment';
import { useState, useEffect } from 'react';

function createTimeState (hours, minutes) {
  return moment({ hours, minutes }).format('hh mm a').split(' ');
}

function useTime (hoursClient, minutesClient) {
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [period, setPeriod] = useState(0);

  useEffect(function () {
    const [hrs, mins, per] = createTimeState(hoursClient, minutesClient);
    setHours(+hrs);
    setMinutes(+mins);
    setPeriod(per);
  }, [hoursClient, minutesClient]);

  const time = { hours, minutes, period };

  function handleHours ({ target }) {
    if (+target.value === 0) {
      setHours(12);
    } else if (+target.value === 13) {
      setHours(1);
    } else {
      setHours(+target.value);
    }
  }

  function handleMinutes ({ target }) {

    if (+target.value > 59) {
      setMinutes(0);
      if (hours === 12) {
        setHours(1);
      }
    } else if (+target.value < 0) {
      setMinutes(59);
      if (hours === 1) {
        setHours(12);
      }
    } else {
      setMinutes(+target.value);
    }
  }

  function handlePeriod ({ target }) {
    setPeriod(target.value);
  }

  return [time, handleHours, handleMinutes, handlePeriod];

}

export default useTime;