import React from 'react';
import PendingIcon from '@mui/icons-material/PauseOutlined';
import ProcessingIcon from '@mui/icons-material/HourglassFullTwoTone';
import SuccessIcon from '@mui/icons-material/Check';
import FailureIcon from '@mui/icons-material/Clear';
import useStyles from './SyncStatus.styles';

const statusIcons = {
  pending: [PendingIcon, '#9e9e9e'],
  processing: [ProcessingIcon, '#03a9f4'],
  succeeded: [SuccessIcon, '#4caf50'],
  failed: [FailureIcon, '#c8221b']
};


function SyncStatus ({ status }) {
  const [StatusIcon, color] = statusIcons[status] || [];
  const classes = useStyles();
  return (
    StatusIcon ?
      <div className={classes.status}>
        <StatusIcon title={status} style={{ color }}/>
        &nbsp;
        <p>{status}</p>
      </div>
      :
      'N/A'
  );
}

export default SyncStatus;