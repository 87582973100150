import React, { useState } from 'react';
import useTime from '../../customHooks/useTime';
import AddBoxIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import useToggle from '../../customHooks/useToggle';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Typography, Divider, Button, Switch } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import HistoryIcon from '@mui/icons-material/History';
import SyncTimeModal from '../../components/SyncTimeModal/SyncTimeModal';
import SyncRequest from '../../components/SyncRequest/SyncRequest';
import { withSnack } from '../../providers/SnackProvider';
import useStyles from './HomeTabs.style';
import { runSyncAction } from '../../actions/syncActions';
import { toggleSyncAction } from '../../actions/settingsActions';
import { formatHoursMinutesToString, convertTimeFromServerToClient } from '../../utils';
import { Link } from 'react-router-dom';

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

function Sync ({ openSnack }) {
  // This state is to disable buttons during requests to prevent multiple calls
  const [toggling, setToggling] = useState(false);
  const [synchronizing, setSynchronizing] = useState(false);

  const [editing, toggleEditing] = useToggle(false);
  const { user } = useSelector(state => state.user);
  const { settings } = useSelector(state => state.settings);
  const { sync: syncRequest } = useSelector(state => state.sync);
  const dispatch = useDispatch();

  const { dailyTimeToSync, isScheduledSyncEnabled } = settings;
  const { hours: hoursSync, minutes: minutesSync } = dailyTimeToSync || {};
  const {
    hours: clientHours,
    minutes: clientMinutes
  } = convertTimeFromServerToClient({
    hours: hoursSync,
    minutes: minutesSync
  });

  const [time, handleHours, handleMinutes, handlePeriod] = useTime(clientHours, clientMinutes);

  async function toggleSyncClick () {
    setToggling(true);
    await dispatch(toggleSyncAction({ isScheduledSyncEnabled }, openSnack));
    setToggling(false);
  }

  async function runSyncClick () {
    setSynchronizing(true);
    await dispatch(runSyncAction(openSnack));
    setSynchronizing(false);
  }

  const classes = useStyles();
  return (
    <Box className={classes.box}>
      <Box className={classes.wrapper}>
        <Typography variant="h5">Last sync request</Typography>
        <Link to="/sync-requests/1" className="link">
          <Button color="primary">
            <><HistoryIcon/>&nbsp; Old sync requests</>
          </Button>
        </Link>
      </Box>
      <SyncRequest syncRequest={syncRequest}/>

      <Divider className={classes.divider}/>

      <Typography variant="h5">Synchronization schedule</Typography>
      <SyncTimeModal
        time={time}
        handleHours={handleHours}
        handleMinutes={handleMinutes}
        handlePeriod={handlePeriod}
        editing={editing}
        toggleEditing={toggleEditing}
      />

      <Box className={classes.textContainer}>
        <Typography className={classes.text} variant="subtitle1">
              Sync daily at:
        </Typography>
        <Typography className={classes.time} variant="subtitle2">
          {formatHoursMinutesToString({ hours: clientHours, minutes: clientMinutes })}
          {user?.isAccountOwner && <Button onClick={toggleEditing}><EditIcon/></Button>}
          <small>{timezone}</small>
        </Typography>
      </Box>


      <Box className={classes.textContainer}>
        <Typography className={classes.text} variant="subtitle1">
              Sync daily:
        </Typography>
        <Typography className={classes.adjustSwitch} variant="subtitle2">
          {user?.isAccountOwner &&
          <Switch
            checked={isScheduledSyncEnabled == null ? false : isScheduledSyncEnabled}
            disabled={toggling}
            onClick={toggleSyncClick}
          />
          }
          {`(Daily synchronization is ${isScheduledSyncEnabled ? 'on' : 'off'})`}
        </Typography>
      </Box>


      {user?.isAccountOwner && <Button className={classes.button}
        variant="contained"
        color="primary"
        disabled={synchronizing}
        onClick={runSyncClick}>
        <AddBoxIcon/>&nbsp; Create a synchronization request
      </Button>}
    </Box>
  );
}

export default withSnack(Sync);