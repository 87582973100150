import React from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Box, TextField, Select, MenuItem, Button, Typography, Stack } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { updateSyncAction } from '../../actions/syncActions';
import { withSnack } from '../../providers/SnackProvider';
import { useStyles, styles } from './SyncTimeModal.style';
import { snackBarCases, convertTimeFromClientToServer } from '../../utils';

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

function SyncTimeModal ({ openSnack,
  time,
  handleHours,
  handleMinutes,
  handlePeriod,
  editing,
  toggleEditing
}) {

  const dispatch = useDispatch();

  function handleUpdateTime (e) {
    e.preventDefault();
    const { minutes, hours, period } = time;

    if (minutes < 0 || minutes > 59) {
      openSnack('Minutes should range between 0 and 59', snackBarCases.warning);
    } else if (hours < 1 || hours > 12) {
      openSnack('Hours should range between 1 and 12', snackBarCases.warning);
    } else {
      const [hoursIn24Format, minutesIn24Format] =
        moment(`${hours}:${minutes} ${period}`, ['hh:mm a']).format('HH mm').split(' ');

      const { hours: serverHours, minutes: serverMinutes } =
        convertTimeFromClientToServer({ hours: hoursIn24Format, minutes: minutesIn24Format });

      const serverTiming = { hours: serverHours, minutes: serverMinutes };
      toggleEditing(false);
      dispatch(updateSyncAction(
        { serverTiming }, openSnack
      ));
    }
  }

  const classes = useStyles();

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={editing}
        onClose={toggleEditing}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={editing}>
          <Box className={classes.box} sx={styles}>
            <Typography className={classes.title} variant="h6">
              Update Sync time
              <small>{timezone}</small>
            </Typography>
            <form onSubmit={handleUpdateTime}>
              <Stack spacing={2}>
                <div className={classes.inputWrapper}>
                  <TextField
                    variant="standard"
                    className={classes.input}
                    label="Hours"
                    onChange={handleHours}
                    name="hours"
                    value={time.hours}
                    type="number"
                  />

                  <TextField
                    variant="standard"
                    label="Minutes"
                    onChange={handleMinutes}
                    name="minutes"
                    value={time.minutes}
                    type="number"
                  />

                  <Select
                    className={classes.select}
                    variant="outlined"
                    name="period"
                    value={time.period}
                    label="AM/PM"
                    onChange={handlePeriod}
                    required
                  >
                    <MenuItem value='am'>AM</MenuItem>
                    <MenuItem value='pm'>PM</MenuItem>
                  </Select>


                </div>
                <div className="buttons-container">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit">
                    Save
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={toggleEditing}>
                    Cancel
                  </Button>
                </div>
              </Stack>
            </form>
          </Box>

        </Fade>
      </Modal>
    </div>
  );
}

export default withSnack(SyncTimeModal);