import React, { useState } from 'react';
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardMedia,
  CardContent,
  Typography,
  Button
} from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { useStyles, styles } from './HomeModal.style';
import { BASE_URL } from '../../CONSTANTS';

function HomeModal () {
  const [open, setOpen] = useState(true);

  function handleClose () {
    setOpen(false);
  }

  const classes = useStyles();

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <Box className={classes.box} sx={styles}>
            <Card className={classes.card}>
              <a className="link" href={`${BASE_URL}/api/customer/hubspot-accounts/connect/`}>
                <CardActionArea style={{ width: 'inherit', margin: '0 auto', display: 'block' }}>
                  <CardMedia
                    component="img"
                    alt="Bidirectional flow between causeway and hubspot"
                    height="200"
                    width="200"
                    style={{ width: 'inherit', margin: '0 auto' }}
                    image="/imgs/logos.jpg"
                    title="Bidirectional flow between causeway and hubspot"
                  />
                  <CardContent>
                    <Typography variant="body2" color="textSecondary" component="p">
                  You are not connected yet to hubspot
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button className={classes.button} size="large" variant="contained" color="primary">
                  Connect To hubspot
                  </Button>
                </CardActions>
              </a>
            </Card>
          </Box>

        </Fade>
      </Modal>
    </div>
  );
}

export default HomeModal;