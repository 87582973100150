import { makeStyles } from '@mui/styles';

export default makeStyles({
  box: {
    margin: '2rem 1rem'
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  reverse: {
    flexDirection: 'row-reverse',
    height: '6rem'
  }
});