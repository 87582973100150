import axios from 'axios';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { asyncHandler, snackBarCases, extractMessage, createConfig } from '../utils';
import {
  USER_LOGGED_FAILED,
  USER_REGISTERED_FAILED,
  USER_TOKEN_RECEIVED,
  USER_LOGGED_SUCCESS,
  USER_LOGGED_LOADING,
  USER_HUBSPOT_CONNECTIN,
  USER_HUBSPOT_RESET_CONNECTION,
  USER_LOGOUT,
  USER_UPDATE_PROFILE
} from './types';
import { getSettingsAction } from './settingsActions';
import { getLastSyncAction } from './syncActions';
import { getAccessToken, storeAtLocalStorage } from './tokenActions';

const config = {
  headers: {
    'Content-Type': 'application/json'
  }
};

async function logInRequest ({ email, password }) {
  return await axios.post('/api/users/login/', { email, password }, config);
}

async function signUpRequest (potentialUser) {
  return await axios.post(
    '/api/customer/users/sign-up/',
    { user: potentialUser },
    config
  );
}

async function getProfileRequest ({ accessToken }) {
  return await axios.get('/api/users/view-profile/',
    createConfig({ accessToken }));
}

async function updateProfileRequest ({ accessToken, user }) {
  return await axios.put('/api/users/update-profile/',
    { user }
    , createConfig({ accessToken }));
}

async function updateNotificationRequest ({ accessToken, user }) {
  return await axios.post('/api/customer/users/update-user-notification/',
    { user }, createConfig({ accessToken }));
}

async function updatePasswordRequest ({ accessToken, currentPassword, newPassword }) {
  return await axios.post('/api/users/update-password/',
    { currentPassword, newPassword }
    , createConfig({ accessToken }));
}

async function hubspotConnectionCallback ({ accessToken, code }) {
  return await axios.post(
    '/api/customer/hubspot-accounts/connection-callback/',
    { code },
    createConfig({ accessToken })
  );
}

async function resetHubSpotConnectionRequest ({ accessToken }) {
  return await axios.delete('/api/customer/accounts/reset-hubspot-settings/',
    createConfig({ accessToken })
  );
}

export function getProfileAction (openSnack, firstTime) {
  return async function (dispatch) {
    dispatch(showLoading());
    dispatch({ type: USER_LOGGED_LOADING });
    const accessToken = await getAccessToken(dispatch);
    const [res, error] = await asyncHandler(getProfileRequest, { accessToken });
    if (res) {
      if (firstTime) {
        openSnack('You logged in successfully', snackBarCases.success);
      }

      res.data.user.isAccountOwner = res.data.user.roles?.includes('account-owner');
      res.data.user.isAdmin = res.data.user.roles?.includes('admin');

      dispatch({ type: USER_LOGGED_SUCCESS, payload: res.data.user, firstTime });
      dispatch(getSettingsAction());
      dispatch(getLastSyncAction());
    } else {
      localStorage.clear();
      const errMsg = error?.response?.data?.message || error.message;
      dispatch({
        type: USER_LOGGED_FAILED,
        payload: errMsg
      });
      openSnack(errMsg, snackBarCases.error);
    }
    dispatch(hideLoading());
  };
}

export function logInAction ({ email, password }, openSnack) {
  return async function (dispatch) {
    dispatch(showLoading());
    dispatch({ type: USER_LOGGED_LOADING });
    const [res, error] = await asyncHandler(logInRequest, { email, password });
    if (res) {
      dispatch(hideLoading());
      dealWithLoginResponse(res.data, { dispatch, openSnack });
    } else {
      const errMsg = error?.response?.data?.message || error.message;
      dispatch({
        type: USER_LOGGED_FAILED,
        payload: errMsg
      });
      // open snack bar
      openSnack(errMsg, snackBarCases.error);
      dispatch(hideLoading());
    }
  };
}

export function signUpAction (potentialUser, openSnack) {
  return async function (dispatch) {
    dispatch(showLoading());
    dispatch({ type: USER_LOGGED_LOADING });
    const [res, error] = await asyncHandler(signUpRequest, potentialUser);
    if (res) {
      dispatch(hideLoading());
      dealWithLoginResponse(res.data, { dispatch, openSnack });
    } else {
      const errMsg = error.response.status === 409 ?
        error?.response?.data || error.message : 'Something went wrong.';

      dispatch(hideLoading());
      dispatch({
        type: USER_REGISTERED_FAILED,
        payload: errMsg
      });
      openSnack(errMsg, snackBarCases.error);

    }
  };
}

// This function is called in three places where we receive tokens
// 1-login  2-signup  3-admin mimic a user
export function dealWithLoginResponse (data, { dispatch, openSnack }, cb) {
  const { accessToken, refreshToken, userId, expirationTimeInSeconds } = data;
  storeAtLocalStorage({ accessToken, refreshToken, userId, expirationTimeInSeconds });
  dispatch({
    type: USER_TOKEN_RECEIVED,
    payload: { accessToken, refreshToken }
  });
  if (cb) {
    cb();
  }
  dispatch(getProfileAction(openSnack, true));

}

export function updateProfileAction ({ user }, openSnack) {
  return async function (dispatch) {
    dispatch(showLoading());
    const accessToken = await getAccessToken(dispatch);
    const [res, err] = await asyncHandler(updateProfileRequest, { accessToken, user });
    if (res) {
      dispatch({ type: USER_UPDATE_PROFILE, payload: user });
      openSnack(res.data.message, snackBarCases.success);
    } else {
      openSnack(err.response?.data?.message || err.message, snackBarCases.error);
    }
    dispatch(hideLoading());
  };
}

export function updatePasswordAction (
  { currentPassword, newPassword }, openSnack, cb) {
  return async function (dispatch) {
    dispatch(showLoading());
    const accessToken = await getAccessToken(dispatch);
    const [res, err] = await asyncHandler(updatePasswordRequest,
      { accessToken, currentPassword, newPassword }
    );
    if (res) {
      openSnack(res.data.message, snackBarCases.success);
      cb();
    } else {
      openSnack(err.response?.data?.message || err.message, snackBarCases.error);
    }
    dispatch(hideLoading());
  };
}

export function updateNotificationAction ({ user }) {
  return async function (dispatch) {
    const accessToken = await getAccessToken(dispatch);
    await asyncHandler(updateNotificationRequest, { accessToken, user })
      .catch((error) => console.log(error));
  };
}

export function hubspotCallbackAction ({ code }, openSnack) {
  return async function (dispatch) {
    const accessToken = await getAccessToken(dispatch);
    const [res, error] = await asyncHandler(hubspotConnectionCallback, { accessToken, code });
    if (res) {
      dispatch(getSettingsAction());
      dispatch({ type: USER_HUBSPOT_CONNECTIN, payload: res.data.hubspotAccountId });
      openSnack(res.data.message, snackBarCases.success);
    } else {
      const errMsg = error?.response?.data?.message || error.message;
      openSnack(errMsg, snackBarCases.error);
    }
  };
}

export function resetConnectionAction (openSnack) {
  return async function (dispatch) {
    const accessToken = await getAccessToken(dispatch);
    const [res, err] = await asyncHandler(resetHubSpotConnectionRequest, { accessToken });
    if (res) {
      dispatch(getSettingsAction());
      dispatch({ type: USER_HUBSPOT_RESET_CONNECTION });
      openSnack(res.data.message, snackBarCases.success);
    } else {
      const errorMessage = extractMessage({
        response: err.response,
        defaultMessage: 'An error occured during resetting, Try again later.',
        isError: true
      });
      openSnack(errorMessage, snackBarCases.error);
    }
  };
}

export function logoutAction () {
  return function (dispatch) {
    localStorage.clear();
    dispatch({ type: USER_LOGOUT });
  };
}