import moment from 'moment';
import 'moment-timezone';

export async function asyncHandler (func, params = {}) {
  try {
    const data = await func(params);
    return [data, null];
  } catch (e) {
    return [null, e];
  }
}

export function extractMessage ({
  response,
  defaultMessage,
  isError
}) {
  const messageFromResponse = response?.data?.message;
  if (messageFromResponse) {
    return messageFromResponse;
  }
  if (defaultMessage) {
    return defaultMessage;
  }
  if (isError) {
    return 'Something went wrong, try again.';
  }

  return 'Operation complete.';
}

export function createConfig ({ accessToken, page, perPage }) {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };

  if (page) {
    config['params'] = {
      $sortBy: '-createdAt',
      $documentsPerPageCount: perPage || 10,
      $pageNumber: page || 1
    };
  }

  return config;
}
export const snackBarCases = {
  success: 'success',
  error: 'error',
  warning: 'warning',
  info: 'info'
};



const SERVER_TIMEZONE = 'America/Los_Angeles';
const CLIENT_TIMEZONE = moment.tz.guess();

export function formatHoursMinutesToString ({ hours, minutes }) {
  return moment.tz({ hours, minutes }, CLIENT_TIMEZONE).format('hh:mm A');
}

export function formatDate (date, format = 'YYYY/MM/DD hh:mm A') {
  if (!date) {
    return 'N/A';
  }

  return moment(date).format(format);
}

export function convertTimeFromClientToServer ({ hours, minutes }) {
  const momentInServerTimezone = moment.tz({ hours, minutes }, CLIENT_TIMEZONE).tz(SERVER_TIMEZONE);
  return {
    hours: momentInServerTimezone.hours(),
    minutes: momentInServerTimezone.minutes()
  };
}
export function convertTimeFromServerToClient ({ hours, minutes }) {
  const momentInCurrentTimezone = moment.tz({ hours, minutes }, SERVER_TIMEZONE).tz(CLIENT_TIMEZONE);
  return {
    hours: momentInCurrentTimezone.hours(),
    minutes: momentInCurrentTimezone.minutes()
  };
}

export function formatMillisecondsToReadableDuration (durationInMilliseconds) {
  const portions = [];

  const msInHour = 1000 * 60 * 60;
  const hours = Math.trunc(durationInMilliseconds / msInHour);
  if (hours > 0) {
    portions.push(`${hours}h`);
    durationInMilliseconds = durationInMilliseconds - (hours * msInHour);
  }

  const msInMinute = 1000 * 60;
  const minutes = Math.trunc(durationInMilliseconds / msInMinute);
  if (minutes > 0) {
    portions.push(`${minutes}m`);
    durationInMilliseconds = durationInMilliseconds - (minutes * msInMinute);
  }

  const seconds = Math.trunc(durationInMilliseconds / 1000);
  if (seconds > 0) {
    portions.push(`${seconds}s`);
  }

  return portions.join(' ');
}