import React, { useEffect } from 'react';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import useToggle from '../../customHooks/useToggle';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Button,
  Checkbox
}
  from '@mui/material';
import UsersInviteModal from '../../components/UsersInviteModal/UsersInviteModal';
import Message from '../../components/Message/Message';
import Loader from '../../components/Loader/Loader';
import useStyles from './HomeTabs.style';
import { getMembersAction } from '../../actions/membersActions';
import { updateNotificationAction } from '../../actions/userActions';

function Users () {
  const [open, toggleOpen] = useToggle(false);
  const { members: accountUsers, loading, err } = useSelector(state => state.members);
  const { user: signedUser } = useSelector(state => state.user);
  const dispatch = useDispatch();
  useEffect(function getUsers () {
    dispatch(getMembersAction());
  }, []);

  async function handleNotifyChange (e) {
    e.preventDefault();
    // console.log(e.target);
    await dispatch(updateNotificationAction({
      user: {
        email: e.target.id,
        emailNotification: e.target.checked
      }
    }));
    dispatch(getMembersAction());
  }

  const classes = useStyles();

  return (
    <>
      <UsersInviteModal open={open} toggleOpen={toggleOpen}/>
      <Box className={classes.box}>
        {err && <Message>{err}</Message>}
        <Typography className={classes.title} variant="h4">Users</Typography>
        {loading && <Loader/>}
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="center">Email</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Notify</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {accountUsers?.map((user, i) => <TableRow key={i}>
                <TableCell>{user.firstName} {user.lastName}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.isAccountOwner ? 'Account owner' : 'Account agent'}</TableCell>
                <TableCell>
                  <Checkbox
                    checked={user.emailNotification}
                    onChange={handleNotifyChange}
                    id={user.email}
                    disabled={!signedUser?.isAccountOwner}
                  />
                </TableCell>
              </TableRow>)}
            </TableBody>
          </Table>
        </TableContainer>
        {signedUser?.isAccountOwner &&
        <Button className={classes.button}
          onClick={toggleOpen}
          variant="contained"
          color="primary"><PersonAddIcon/> &nbsp;Invite User</Button>}
      </Box>
    </>
  );
}

export default Users;